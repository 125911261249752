.multi-graph{
    width: 300px;
    height: 150px;
    position: relative;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    box-sizing : border-box;
     &:before{
      content: '';
      width: 300px;
      height: 150px;
      border:50px solid rgba(0,0,0,.15);
      border-bottom: none;
      position: absolute;
      box-sizing : border-box;
      transform-origin: 50%  0%;
      border-radius: 300px 300px 0 0 ;
      left: 0;
      top: 0;
    }
    .graph{
      width: 300px;
      height: 150px;
      border:50px solid var(--fill);
      border-top: none;
      position: absolute;
      transform-origin :50%  0% 0;
      border-radius: 0 0 300px 300px ;
      left: 0;
      top: 100%;
      z-index: 5;
      animation : 1s fillGraphAnimation ease-in;
      transform: rotate( calc( 1deg * ( var(--percentage) * 1.8 ) ) );
      box-sizing : border-box;
      cursor: pointer;
      &:after{
        // content: attr(data-name) ;
        content: attr(data-name) ' ' counter(varible) '%';
        counter-reset: varible var(--percentage);
        // background: var(--fill) ;
        box-sizing : border-box;
        border-radius : 2px;
        color: #fff;
        font-weight: 200;
        font-size: 12px;
        height: 20px;
        padding: 3px 5px;
        top: 0px;
        position: absolute;
        left: 0;
        transform: rotate(calc( -1deg * var(--percentage) * 1.8 ))  translate(-30px , 0px);
        transition:0.2s ease-in;
        transform-origin: 0 50% 0;
        opacity: 0;
      }
      &:hover{
          opacity: 0.8;
        &:after{
          opacity: 1;
          left: 30px;
        }
      }
    }
  }
  
  
  
  @keyframes fillAnimation{
    0%{transform : rotate(-45deg);}
    50%{transform: rotate(135deg);}
  }
  
  @keyframes fillGraphAnimation{
    0%{transform: rotate(0deg);}
    50%{transform: rotate(180deg);}
  }