@import '../index.scss';

.tracking_details_page {
    .h-ctrl {
        min-height: 100vh;
    }

    h5 {
        font-size: 20px;
        font-weight:700;
        color: $primary-color;
    }

    .card {
        background-color: rgba(203, 198, 198, 0.493);

        .vertical-timeline-element-title {
            font-size: $FS-18;
            font-weight: $FW-600;
            margin-bottom: 2px;
        }

        .vertical-timeline-element-subtitle {
            font-weight: $FW-500;
            font-size: $FS-16;
        }

        .vertical-timeline-element-date {
            padding-top: 3px;
        }
    }


}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    span {
        font-size: 10px;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        line-height: 20px;
        transform: rotate(45deg);
        width: 100px;
        display: block;
        background: rgb(108, 245, 108);
        background: linear-gradient(#57ee29 0%, #65f740 100%);
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 19px;
        right: -21px;

        &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: 100%;
            z-index: -1;
            border-left: 3px solid #79A70A;
            border-right: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #79A70A;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0%;
            top: 100%;
            z-index: -1;
            border-right: 3px solid #79A70A;
            border-left: 3px solid transparent;
            border-bottom: 3px solid transparent;
            border-top: 3px solid #79A70A;
        }
    }

}