@import '../index.scss';

.exchange_products {
    margin-bottom: 70px;
  .loader{min-height:calc(100vh - 145px);}
    .zindex-1{
        position: relative;
        z-index:999;
        .css-1nmdiq5-menu{
            position: relative;
            z-index: 999;
        }
    }
    .zindex-2{
        position: relative;
        z-index:888;
        .css-1nmdiq5-menu{
            position: relative;
            z-index: 888;
        }
    }
    .zindex-3{
        position: relative;
        z-index:777;
        .css-1nmdiq5-menu{
            position: relative;
            z-index: 777;
        }
    }
    .zindex-4{
        position: relative;
        z-index:666;
        .css-1nmdiq5-menu{
            position: relative;
            z-index: 666;
        }
    }
    .zindex--1{
        position: relative;
        z-index:-1;
    }
    .filter-icon {
        border-radius: 4px;
        background-color: $primary-color;
        height: 38px;
        width: 38px;
        display: grid;
        place-items: center;

        svg {
            font-size: $FS-18;
            color: $text-white;
        }
    }

    .nodatafound-h-ctrl {
        min-height:calc(100vh - 50px);
    }

    input[type=text] {
        padding: 5px;
        border: 1px solid rgb(198, 194, 194);
        border-radius: 4px;
    }

    .card {
        .list-group-item {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: $FS-14;
            font-weight: $FW-400;

            label {
                width: 112px;
            }

            p {
                margin: 0;
                font-size: $FS-16;
                font-weight: $FW-500;
                width: calc(100% - 119px);
            }
        }
    }
}

// .exchange_modal {
//     // .modal-dialog-scrollable .modal-content{
//     //     position: absolute;
//     //     top:20px;
//     // }
//     .modal-body {
//         h3 {
//             color: $primary-color;
//         }

//         .border-top {
//             border-top: 1px solid rgb(226, 224, 224);
//         }

//         div>div {
//             display: flex;
//             align-items: center;
//         }

//         label {
//             font-size: $FS-14;
//             font-weight: $FW-600;
//             width: 243px;
//         }

//         p {
//             font-size: $FS-16;
//             font-weight: $FW-400;
//             line-height: 16px;
//             text-align: left;
//             display: inline-block;
//             margin: 0;
//             text-align: left;
//             margin-right: auto;
//             width: calc(100% - 55px);
//         }
//     }
// }

