@import "../../index.scss";

.profitability_modal{
    .modal-content{
    background-image: url(../../assets/modal_bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    filter: opacity(0.9);
    color: white;
    // filter: grayscale(10%);
//     &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(131, 220, 247, 0.7); 
//     z-index: 1; 
// }
    }
    }
.profitability {
    background-color: #203147;
    // .table>:not(caption)>*>*{
    //     background-image: url(../../assets/card-krishna.jpg);
    //     background-position: center;
    //     background-size: cover;
    //     background-repeat: no-repeat;

    // }
    .loading {
        h1 {
            text-transform: capitalize;
            font-size: $FS-22;
            color: rgb(209, 146, 30);
        }
    }

    .nav-tabs {
        border-bottom: 1px solid #cdcbcb;

        .nav-item {
            font-size: 11px;
            font-weight: $FW-500;
        }
    }

    &>div {
        // min-height: 100vh;
        max-height: auto;
        background: #fce8c1;
        // background: #154460;
    }

    // .color{background-color: #feac00;}
    // .flag_card{
    // background-image: url(../../assets/flag.png);
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    // }
    // .nav-tabs .nav-link.active{
    // background-image: url(../../assets/flag.png) !important;
    // background-position: center !important;
    // background-size: cover !important;
    // background-repeat: no-repeat !important;
    // background-color: transparent !important;
    // color: black !important;
    // font-weight: 500 !important;
    // font-size: 14px !important;
    // background-color: white !important;
    // }
    //    .profitsheets-table{
    //     tbody{
    // tr>td{background-color: transparent;}
    // tr:nth-child(odd){
    //     background-color:#FF671F ;
    // }
    // tr:nth-child(even){

    // }
    // tr:nth-child(odd){

    // }
    // tr:nth-child(3n+1) td {
    // background: linear-gradient(to right, #ff9933 33%, #ffffff 33%, #ffffff 66%, #138808 66%);
    // border:2px solid orange;
    // color:orange;
    // font-weight: 700;
    // }
    // tr:nth-child(3n+2) td{
    // background: linear-gradient(to right, #ffffff 33%, #ff9933 33%, #ff9933 66%, #138808 66%);
    // border:2px solid white;
    // color:#06038D; 
    // font-weight: 400;
    // }
    // tr:nth-child(3n+3) td {
    // background: linear-gradient(to right, #138808 33%, #ffffff 33%, #ffffff 66%, #ff9933 66%);
    // border:2px solid green;
    // color:green;
    // font-weight: 700;
    // }
    //     }
    //    }
    // .orange{color:#FF671F;font-weight: 600;}
    // .white{color:#FFFFFF;font-weight: 600;}
    // .green{color:#046A38;font-weight: 600;}
    .flag_transaction_card {
        background: #ffffff6e;
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 11px;
    }

    .flag_table{
            background-image: url(../../assets/card-krishna.jpg) !important;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        height:598px !important;
        .rnvodz57-0-0-beta-42{max-height: 596px;}
        .cj343x07-0-0-beta-42{background-color: transparent !important;}
        .cj343x07-0-0-beta-42{color:white !important;}
    }

    // .flag_cards{
    // .row>.card:nth-child(even) {
    //         background-image: url(../../assets/flag.png);
    //         background-position: center;
    //         background-size: cover;
    //         background-repeat: no-repeat;
    //         background-color: red;
    //     }
    // }

    .zindex-1 {
        z-index: -1;
        position: relative;
    }

    .affort {
        h6 {
            writing-mode: revert !important;
            transform: none !important;
            font-size: 18px !important;
            font-weight: 700 !important;
        }

        h5 {
            text-align: center !important;
            font-size: 18px !important;
            margin-top: 10px !important;
        }
    }

    .css-1nmdiq5-menu {
        // z-index: 1000;
        position: relative;
        // border:2px dashed red;
    }

    .profit {
        background-color: rgba(255, 215, 0, 0.2);
        /* Transparent warm orange */
        color: #000;
        /* Black text */

        // background-image: url(../../assets/flag.png);
        // background-position: center;
        // background-size: cover;
        // background-repeat: no-repeat;
        .img {
            height: 65px;
            width: 65px;
            // border: 2px solid gold; /* Golden yellow border */

        }
    }

    max-height: 100vh;

    .tabprice-cards .card {
        height: 100%;
    }

    .card {
        box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
        // background-image: url(../../assets/card-krishna.jpg);
        // background-position: center;
        // background-size: cover;
        // background-repeat: no-repeat;
        border: none;
        border-radius: 20px 30px 20px 30px;
        position: relative;

        //         &::before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: rgba(255, 255, 255, 0.7);
        //     z-index: 1;
        // }
        .nav {
            width: fit-content;
        }

        h6 {
            writing-mode: vertical-lr;
            text-orientation: sideways-right;
            font-size: $FS-20;
            font-weight: $FW-500;
            transform: rotate(180deg)
        }

        h5 {
            font-size: $FS-18;
            font-weight: $FW-500;
            // color:
        }

        .circular-progress-bar {
            height: 100px;
            width: 100px;
            margin: auto;
        }

        .nav-link {
            padding: 10px !important;
            font-size: 0.7px !important;
        }

    }

    .nodatafound-h-ctrl {
        min-height: 83vh;
    }
}

.text-white-pf{
    div{
color:white !important;
 }
}

.fs-last-sync{
    font-size: 13px !important;
    margin:0 !important;
}

.filter-icon-parent {
    position: absolute;
    top: 0;
    right: 0;

    .filter-icon {
        border-radius: 4px;
        background-color: $primary-color;
        height: 38px;
        width: 38px;
        display: grid;
        place-items: center;


        svg {
            font-size: $FS-18;
            color: $text-white;
        }
    }
}

.modal .filter-icon-parent {
    top: -59px;
}

.rnvodz57-0-0-beta-42 {
    max-height: 500px;

    &:nth-child(odd) {
        background: transparent;
    }

    &:nth-child(even) {
        background: $text-white;
    }
}

.react-datepicker-wrapper .react-datepicker__input-container {
    background: transparent;
}

.datepicker {
    width: 100% !important;
}

.iconcard {
    .profitText {
        font-size: 40px !important;
        color: black;
    }

    p {
        color: rgba(57, 57, 57, 0.714);
        font-weight: 600;
        font-size: $FS-20;
        text-wrap: nowrap;
        line-height: 13px;

        span {
            font-size: 8px;
            color: $neutral-50;
            text-align: center;
        }
    }

    .img {
        width: 48px;
        height: 48px;
        display: grid;
        place-items: center;
        // border: 2px solid gold; /* Golden yellow border */


        img {
            width: 100%;

        }

        .gross {
            height: 100%;
        }

        .remaining {
            height: 80%;
            width: 80%;
        }
    }
}

.rupees {
    font-size: $FS-16;
    font-weight: $FW-700;
    text-align: center;
    color: revert;
}

.acc-header {
    font-size: 16px;
}

.accordion-header {
    .accordion-button {
        padding: 10px;
    }

    .accordion-button:focus {
        box-shadow: 0 0 0 0.25rem rgba(225, 172, 91, 0.25) !important;
    }



    .accordion-button:not(.collapsed) {
        background-color: #f1dec5;
    }

    .accordion-button::after,
    .accordion-button:not(.collapsed)::after {
        background-image: unset !important;
        content: none !important;
    }

    .accordion-button::before {
        content: "";
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        margin-right: 8px;
        background-repeat: no-repeat;
        background-size: var(--bs-accordion-btn-icon-width);
        transition: var(--bs-accordion-btn-icon-transition);
        background-image: url(../../assets/pluse.png) !important;
    }

    .accordion-button:not(.collapsed)::before {
        transform: var(--bs-accordion-btn-icon-transform);
        background-image: url(../../assets/minus.png) !important;
    }
}

.accordion-body {
    padding: 10px !important;

    .w-ctrl {
        min-width: calc(100% - 110px);
        margin-right: 10px;
        padding-right: 10px;
        // border:2px dashed red;
    }
}

.accordion-button h6,
.accordion-body h6 {
    font-size: 12px;

    span {
        font-size: 14px;
    }
}

// .ww-ctrl {
//     min-width: calc(100% - -150px);
// }

.w-ctrl {
    width: calc(100% - 100px);
    margin-right: 10px;
    padding-right: 10px;
    // border:2px dashed red;
}

.w-ctrl-2 {
    width: calc(100% - 150px);
    // border:2px dashed green;
    padding-right: 10px;
}

.w-ctrl-3 {
    // border:2px dashed pink;
    width: calc(100% - 150px);
}

.search {
    top: 7px;
    left: 12px;
}

.clear {
    top: 7px;
    right: 3px;

}

// table {
//     tr {
//         border-bottom: 1px solid rgb(231, 227, 227);
//     }

//     td {
//         font-size: 12px;
//         font-weight: 600;
//     }

//     td:first-child {
//         width: calc(100% - 5px);
//     }
// }

.selectdate {
    label {
        text-wrap: nowrap;
        line-height: 16px;

        span {
            font-size: 10px;
            color: $neutral-50;
        }

    }

    .label-w-ctrl {
        width: 80px;
    }

    .react-datepicker-wrapper .react-datepicker__input-container {
        height: 30px;

        input {
            width: 100%;
        }
    }
}

.profitsheets-table {
    td:nth-child(2) {
        max-width: 100px;
        word-wrap: break-word;
    }
}


@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (orientation: landscape) {
    .profitability_modal {
        height: calc(100vh - 60px);
    }
}

@media (min-width: 576px) {
    .modal {
        height: calc(100vh - 60px) !important;
        width: 100% !important;
        --bs-modal-width: 100% !important;
    }
}

td {
    font-size: 0.8rem;
}

input[type="checkbox"] {
    accent-color: rgba(250, 144, 18, 0.66);
}

.mop {
    span {
        font-size: 10px;
    }
}

.nav-tabs {
    font-size: 0.7rem;

    .nav-link {
        padding: 7px;
    }
}

.state-head-label {
    width: calc(100% - 244px);
    // font-weight: $FW-400;
    font-size: $FS-20;
}

.filter-icon {
    border-radius: 100%;
    background-color: $primary-color;
    height: 38px;
    width: 38px;
    display: grid;
    place-items: center;
    // background-image: url(../../assets/flag.png);
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;

    svg {
        font-size: 1.25rem;
        // color: #06038D;
    }
}

.p-modal {
    background-color: #7c7c7c17 !important;

    .modal-backdrop.show {
        opacity: 0 !important;
    }
}

.rankingshowFilter-label {
    text-wrap: nowrap;
    margin-right: 3px;
    min-width: 85px;
}