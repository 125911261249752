@import "../../styles/variables";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$dark-textColor: #FFFFFF;
// $dark-cardbgcolor: #1e293b;
$dark-cardbgcolor: #1c252e;
// #13191f
$dark-bordercolor: #64748b;

.inentives {
    font-family: "Roboto", sans-serif;
    position: relative;
    max-height: 100vh;
    .title-crd{
        h5{
            font-size: 15px;
            font-weight: 400;
        }
        // h6{
        //     font-size: 12px;
        //     font-weight: 400;
        // }
    }
    .title{
        color: $primary-color !important;
        font-size: 20px;
        font-weight: 700;
    }
    // .bar-chart {
    //     p{
    //         margin-top:-33px !important;
    //     }
    // }

    .incentive-amount {
        span {
            color: $primary-color;
        }
    }
        

    .css-t3ipsp-control {
        // background-color: $dark-cardbgcolor;
        box-shadow: none;

        &:hover {
            border: none;
        }

        & .css-1nmdiq5-menu {
            background-color: $dark-cardbgcolor;
            border:3px dashhed red;
        }
    }

    .h-ctrl {
        // max-height: calc(100vh - 90px);
        // overflow-y: auto;
        padding-right: 5px;
    }

    .slot-counter-div {
        h6 {
            font-size: 10px;
            font-weight: $FW-400;

            span {
                font-size: 20px;
                color: $primary-color;
                font-weight: $FW-700;
            }
        }
    }

    .inc-img {
        width: 50px;
        height: 50px;

        img {
            max-width: 100%;
        }
    }

    .css-13cymwt-control {
        border: none;
        border-radius: 4px;
    }

    .dropdown-menu.show {
        width: 100%;
    }

    .swiper {
        z-index: -1;
        // max-height: calc(100vh - 60px);
    }

    // .dropdown-menu {
    //     height: 100px;
    //     overflow-y: scroll;
    // }

    .swiper-div {
        // min-height: calc(100vh - 500px);
        margin-bottom: 10px;
    }

    .lottie {
        min-height: calc(100vh - 124px);
    }

    .tabs.all {
        width: 100%;
    }

    .tabs {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        width: calc(100% - 38px);
        display: flex;
        border-bottom: 1px solid #dee2e6;

        button {
            appearance: none;
            border: none;
            background-color: transparent;
            text-wrap: nowrap;
            font-size: 12px;
        }

        .tab.active {
            background-color: $primary-color;
            border-radius: 4px 4px 0 0;
            color: #eeecec;
        }
    }

    .mobil-tabs {
        .tab.active {
            color: $primary-color;
            background-color: transparent;
            border-bottom: 1px solid $primary-color;
            font-weight: $FW-700;
        }
    }

    .checkbox-parent {
        position: absolute;
        top: 13px;
        right: 2%;
        z-index: 9;
        background-color: #eeecec;
        padding: 3px 6px 5px 6px;
        border-bottom: 1px solid #dee2e6;

        .fa-moon,
        .fa-sun,
        .fa-filter {
            color: $primary-color;
            font-size: 12px;
        }
    }

    .watermark {
        min-height: 100vh;
    }

    p,
    h6 {
        margin: 0;
    }

    h6 {
        font-size: 16px;
        font-weight: $FW-700;
        margin-top: 10px;

        &>span {
            font-size: 1rem;

            font-weight: $FW-400;

        }
    }

    .card {
        box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        border: none;
        .content{width:calc(100% - 40px);}
        .icon {
            height: 38px;
            width: 38px;
            display: grid;
            place-items: center;
            img{max-width:100%;}
            svg {
                font-size: 30px;
            }
        }


    }

    .inc {
        h6 {
            font-size: 18px;
            margin: 0;
            font-weight: $FW-700;
        }
        h5{font-size: 1rem;font-weight: $FW-500;color:$primary-color !important;}
        .content{width:calc(100% - 42px);}
        .inc-icon {
            height: 40px;
            width: 40px;
            img{
                width:100%;
            }
            svg {
                font-size: 25px;
            }
        }
    }
}


// @media screen and (min-device-width: 360px) and (max-device-width: 690px) {
//     .incentives{
//         .bar-chart{
//             p{
//                 margin-top:0px !important;
//             }
//         }
//     }
//   }

@media only screen and (max-width: 600px) {
    .incentives{
                .bar-chart{
                    p{
                        margin-top:0px !important;
                    }
                }
            }
}



.inentives.darkmode {
    .css-t3ipsp-control {
        background-color: $dark-cardbgcolor;}
    .swiper-div {

        td,
        th {
            color: $dark-textColor;
        }
    }

    .h-ctrl {
        scrollbar-color: #11161d #323e50;
    }

    .css-16xfy0z-control{
        background-color: #2a3641;
        border:none;
        .css-1jqq78o-placeholder,.css-1xc3v61-indicatorContainer{
            color:#5d5d5d !important;
        }
        .css-894a34-indicatorSeparator{
            background-color:#5d5d5d !important;
        }
    }

    .css-1jqq78o-placeholder,
    .css-1dimb5e-singleValue {
        color: $dark-textColor;
    }

    .css-13cymwt-control {
        background-color: $dark-cardbgcolor;
    }

    .checkbox-parent {
        background-color: #11161d;
        border-bottom: 1px solid $dark-bordercolor;
    }

    .watermark {
        // background-color: #475569;
        background-color: #11161d;
    }

    .tabs {
        border-bottom: 1px solid $dark-bordercolor;

        // scrollbar-color:#11161d #323e50;
        button {
            color: #eeecec;
        }

        // .tab.active {
        //     color: #eeecec;
        // }
    }

    .card {
        background-color: $dark-cardbgcolor;

        .icon {
            svg {
                color: white;
            }
        }

    }

    p,
    h6,
    h5 {
        color: $dark-textColor;
    }
}

