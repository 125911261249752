@import './styles/variables','./styles//reset';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  background-color: $primary-color !important;
  border: none !important;
}

.page-item.active .page-link {
  background-color: #FB9013 !important;
  border: none;
}

.discount_rules input {
  padding: 8px 10px;
  border-radius: 4px;
}

.containerStyles {
  margin: 0 auto;
  max-width: 960px;
  padding: 0 15px;
}

.activePage .page-link {
  background-color: #FB9013 !important;
  color: #FFFFFF !important;
  border: none;
}



.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: #FB9013;
  background-color: '#rgba(255,184,34,.1)';
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #FB9013;
  color: #ffffff
}

.button .icon {
  margin-right: 8px;
}

.discount_rules {
  display: flex;
  justify-content: flex-end;
}

.editbutton_container {
  display: flex;
  justify-content: center;
}

.editbutton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
  color: #ffb822;
  background-color: '#rgba(255,184,34,.1)';
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editbutton:hover {
  background-color: #ffb822;
  color: #ffffff
}

.containerStyles {
  padding: 20px !important;
}

.nav-tabs .nav-link.active {
  background: #fb9013 !important;
  color: #ffffff !important;
}

.nav-tabs .nav-link {
  color: #000 !important;
}

.discount_rules button {
  border: 1px solid #fb9013;
}

.inputText {
  font-size: 13px;
  font-weight: 300;
}

.react-datepicker__input-container {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-select {
  font-size: 0.8rem !important;
}

.form-control {
  font-size: 0.8rem !important;
  padding: 10px 15px !important;
  &:focus{
    box-shadow: none !important;
    border-color: transparent !important;
  }
}

.form-label {
  font-size:$FS-14;
  font-weight: $FW-500;
  margin-bottom: 3px !important;
  color:$neutral-50;
}

.submitButton {
  display: block !important;
  margin-left: auto;
  background-color: #fa9012 !important;
  border: #ffb822 !important;
}

.addrule_btn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  font-weight: bold;
  color: #ffffff !important;
  background-color: #fa9012 !important;
  border: none !important;
  border-radius: 4px;
  cursor: pointer;
  gap: 3px;
}

.addrule_btn:hover {
  background-color: #fa9012 !important;
  color: #ffffff !important
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #e8ebf1;

  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #ccc;
}

input:focus+.slider {
  box-shadow: 0 0 1px #e8ebf1;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked+.slider:before {
  background-color: #fbcc0a;
  ;
}

.cj343x07-0-0-beta-42 {
  background-color: white;
  color: #6c7293;
  text-align: center;
}

.rnvodz57-0-0-beta-42 {
  min-height: auto;
  height: auto;
  max-height: 350px;
}

.editbutton {
  color: #fa9012;
  background-color: rgba(197, 128, 45, 0.1);
}

.delete-btn {
  display: grid !important;
  place-items: center;
  margin: auto;
}

.form-check-input:checked {
  border-color: #fa9012 !important;
  background-color: #fa9012 !important;
}

.form-check-input:checked .discount-table {
  display: flex;
  align-items: center;
  justify-content: center;

}

.form-check-input:not(:checked) {
  border-color: #fa9012 !important;
  background-color: #dfdcdc !important;
  border: none;
}

.discount-table .form-switch {
  margin-top: 2px;
}

.discount-table .form-check-input[type=checkbox] {
  width: 47px;
  height: 22px;
}


.id {
  border: 2px dotted red;
}

.scroller {
  height: 150px;
  overflow-y: auto;
}

.dropdown-container {
  position: relative;
  outline: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown-heading {
  position: relative;
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  cursor: default;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.dropdown-heading svg {
  fill: rgb(205, 201, 201);
}

.dropdown-heading-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.dropdown-heading-value span {
  font-size: 0.9rem;
  font-weight: 500;
}

.grey {
  color: #aaa;
}

.multiselectdropdown label {
  color: #514f4f;
  font-size: 12px;
  font-weight: 400;
}

.multiselectdropdown input[type=text] {
  border: none;
  padding: 10px 15px;
  border-bottom: 1px solid rgb(213, 210, 210);
}

.multiselectdropdown input[type=text]:focus {
  outline: none;
}

.multiselectdropdown .de-select-btn,
.multiselectdropdown .select-all {
  background-color: rgb(217, 214, 214);
  border: none;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.form-control {
  padding: 0.675rem .75rem;
}



.table-responsive .table {
  width: auto;
  min-width: 250px;
  overflow-y: auto;
}


.discount_form {
  .form-check-input[type=checkbox] {
    height: 26px;
    width: 65px;
    &:focus{
      box-shadow: none;
    }
    &:not(:checked){
      background-color: #ffffff !important;
    }
  }

  tbody td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    width: 105px;
  }
}

.table_styles {
  tbody {
    td {
      min-width: 90px;

      &:nth-child(1) {
        min-width: 132px;
      }
    }

    .table_dis_number {
      min-width: 180px;
    }

    .table_exp_number {
      min-width: 130px;
    }
  }
}

.damaged-products-modal {
  .card {
    background-color: $primary-color;
    color: $text-white;
    border: none;

    label {
      font-size: $FS-16;
      font-weight: $FW-600;
    }

    p {
      font-size: $FS-14;
      font-weight: $FW-400;
      line-height: 16px;
    }
  }

  .btn-primary {
    background: $success-color;
  }
}

.damaged-products {
  .card {
    box-shadow: 0 0 7px #f5cd9d !important;
    border: none;

    label {
      font-size: $FS-18;
      font-weight: $FW-600;
      width: 150px;
    }

    p {
      font-size: $FS-18;
      font-weight: $FW-400;
      line-height: 16px;
      text-align: left;
    }

    .btn-primary {
      background-color: #f5cd9d !important;
      color: $primary-color;
      font-size: $FS-14;
      font-weight: $FW-600;
      border: none;
      border-radius: 0;

      &:hover {
        color: $text-white;
        background-color: $primary-color;
      }
    }
  }

}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    border: none;
    width: 100%;
    padding: 0;
    height: 38px;

    .react-datepicker__calendar-icon {
      right: 0;
      top:4px;
    }

    .form-control {
      height: 38px;
      &::placeholder {
        font-size: $FS-12;
        font-weight: $FW-500;
        padding: 6px;
      }
    }

  }
}

