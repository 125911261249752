$primary-color: #fa9012;
$text-white: rgb(255, 255, 255);
$text-white-75: rgba(255, 255, 255,0.75);
$success-color: #059669;
$body-background:rgb(238, 236, 236);
$neutral:rgb(0, 0, 0);
$neutral-50:rgba(0, 0, 0,0.5);

$FS-12: 0.75rem; //12px
$FS-14: 0.875rem; //14px
$FS-16: 1rem; //16px
$FS-18: 1.125rem; //18px
$FS-20: 1.25rem; //20px
$FS-22: 1.375rem; //22px


$FW-400: 400;
$FW-500: 500;
$FW-600: 600;
$FW-700: 700;
$FW-800: 800;