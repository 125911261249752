:root { 
    --c1: #e49f4a;
    --c2: #fa9012;
    --c3: #4b5842;
    --bg: #293124;
    --sp: 2s;
    --dl: -2s;
    --ttf: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  
  * {
    transform-style: preserve-3d;
    box-sizing: border-box;
  }
  .body,.incentiveloader{
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // background: 
    //   radial-gradient(ellipse at 0% 100%, #fff0 0 80%, #fff2), 
    //   radial-gradient(ellipse at 100% 0%, #fff0 0 80%, #222), 
    //   var(--bg);
    perspective-origin: 50% 50%;
    perspective: 1500vmin;
  
  .content {
    width: 40vmin;
    height: 40vmin;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition: all 0.33s ease 0s;
    transform: rotateX(50deg) rotate(45deg);
    margin-top: 18vmin;
    border-radius: 100%;
    animation: floating 2s ease-in-out -3s infinite alternate;
  
    &:before {
      content: "";
      position: absolute;
      width: 16vmin;
      height: 16vmin;
      background: #0001;
      animation: shadow 2s var(--ttf) var(--dl) infinite alternate;
      filter: blur(5px);
      transform: translateZ(-10vmin);
    }
  }
  
  @keyframes floating {
    50% { margin-top: 14vmin; }
  }
  
  @keyframes shadow {
    0%, 25% {
      width: 12vmin;
      height: 12vmin;
      transform: translateZ(-24vmin);
      filter: blur(10px);
    } 
    100% {
      width: 16vmin;
      height: 16vmin;
      transform: translateZ(-18vmin);
    }
  }
  
  @keyframes leaping-1 {
    0%, 25% { transform: translateZ(2.5vmin); }
    100% { transform: translateZ(-5vmin); }
  }
  
  @keyframes leaping-2 {
    0%, 25% { transform: translateZ(2.5vmin); }
    100% { transform: translateZ(22vmin); }
  }
  
  @keyframes leaping-3 {
    0%, 25% { transform: translateZ(2.5vmin); }
    100% { transform: translateZ(40vmin); }
  }
  
  .cuboid {
    --width: 20;
    --height: 20;
    --depth: 5.5;
    position: absolute;
    height: calc(var(--height) * 1vmin);
    width: calc(var(--width) * 1vmin);
    transform: translateZ(2.5vmin);
    animation: leaping-1 var(--sp) var(--ttf) var(--dl) infinite alternate;
  
    &:nth-child(2) {
      --width: 12.2;
      --height: 12.2;
      transform: translateZ(2.65vmin);
      animation-name: leaping-2;
    }
  
    &:nth-child(3) {
      --width: 5.75;
      --height: 5.75;
      transform: translateZ(2.675vmin);
      animation-name: leaping-3;
    }
  
    .side {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      border-radius: 2px;
      background: var(--c3);
  
      &:nth-of-type(1) {
        transform: translate3d(-50%, -50%, calc(var(--depth) * 0.5vmin));
        background: var(--c1);
        
        &:before {
          content: "";
          position: absolute;
          width: 60%;
          height: 60%;
          background: conic-gradient(from -45deg at 4.95vmin 4.99vmin, var(--c3) 0 135deg, var(--bg) 0 225deg, var(--c2) 0 100%);
          left: 20%;
          top: 20%;
        }
      }
  
      &:nth-of-type(2) {
        transform: translate3d(-50%, -50%, calc(var(--depth) * -0.5vmin)) rotateY(180deg);
      }
  
      &:nth-of-type(3) {
        width: calc(var(--depth) * 1vmin);
        transform: translate(-50%, -50%) rotateY(90deg) translate3d(0, 0, calc(var(--width) * 0.5vmin));
        background: var(--c2);
      }
  
      &:nth-of-type(4) {
        width: calc(var(--depth) * 1vmin);
        transform: translate(-50%, -50%) rotateY(-90deg) translate3d(0, 0, calc(var(--width) * 0.5vmin));
      }
  
      &:nth-of-type(5) {
        height: calc(var(--depth) * 1vmin);
        transform: translate(-50%, -50%) rotateX(90deg) translate3d(0, 0, calc(var(--height) * 0.5vmin));
      }
  
      &:nth-of-type(6) {
        height: calc(var(--depth) * 1vmin);
        transform: translate(-50%, -50%) rotateX(-90deg) translate3d(0, 0, calc(var(--height) * 0.5vmin));
        background: var(--c3);
      }
    }
  
    &:nth-child(1) .side:nth-of-type(1):before {
      content: "";
      position: absolute;
      width: 60%;
      height: 60%;
      background: conic-gradient(from -45deg at 4.95vmin 4.99vmin, var(--c3) 0 135deg, var(--bg) 0 225deg, var(--c2) 0 100%);
      left: 20%;
      top: 20%;
    }
  
    &:nth-child(2) .side:nth-of-type(1):before {
      content: "";
      position: absolute;
      width: 44%;
      height: 44%;
      background: conic-gradient(from -45deg at 5.45vmin 5.35vmin, var(--c3) 0 135deg, var(--bg) 0 225deg, var(--c2) 0 100%);
      left: 27%;
      top: 27%;
    }
  }
  h1{
    margin-top: 62vmin !important;
    position: absolute;
    color: gray !important;
  }
}
  