@import '../index.scss';

.term_page {
    .h-ctrl {
        min-height: 100vh;
    }

    h6 {
        font-size: 20px;
        font-weight: 700;
        color: $primary-color;
    }

    li {
        font-size: $FS-16;
        font-weight: $FW-400;
    }
}

.transferdetails_modal {

    .modal-body {
        max-height: calc(100vh - 60px);
        overflow-y: auto;

        td {
            font-weight: $FW-400;
            font-size: $FS-16;

            &:nth-child(3) {
                font-weight: $FW-500;
            }
        }

    }
}