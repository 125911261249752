@import "../../index.scss";

.loader {
    min-height: 100vh;
    display: grid;
    place-items: center;

    .loading {
        margin: 5px 10px;
        text-align: center;
        margin-right: 5px;

        .fingerprint-spinner {
            height: 200px;
            width: 200px;
            padding: 2px;
            overflow: hidden;
            position: relative;

            .spinner-ring {
                position: absolute;
                border-radius: 50%;
                border: 3px solid transparent;
                border-top-color: $primary-color;
                animation: fingerprint-spinner-animation 1500ms cubic-bezier(0.680, -0.750, 0.265, 1.750) infinite forwards;
                margin: auto;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;

                &:nth-child(1) { height: calc(120px / 9 + 0 * 120px / 9); width: calc(120px / 9 + 0 * 120px / 9); animation-delay: calc(50ms * 1); }
                &:nth-child(2) { height: calc(120px / 9 + 1 * 120px / 9); width: calc(120px / 9 + 1 * 120px / 9); animation-delay: calc(50ms * 2); }
                &:nth-child(3) { height: calc(120px / 9 + 2 * 120px / 9); width: calc(120px / 9 + 2 * 120px / 9); animation-delay: calc(50ms * 3); }
                &:nth-child(4) { height: calc(120px / 9 + 3 * 120px / 9); width: calc(120px / 9 + 3 * 120px / 9); animation-delay: calc(50ms * 4); }
                &:nth-child(5) { height: calc(120px / 9 + 4 * 120px / 9); width: calc(120px / 9 + 4 * 120px / 9); animation-delay: calc(50ms * 5); }
                &:nth-child(6) { height: calc(120px / 9 + 5 * 120px / 9); width: calc(120px / 9 + 5 * 120px / 9); animation-delay: calc(50ms * 6); }
                &:nth-child(7) { height: calc(120px / 9 + 6 * 120px / 9); width: calc(120px / 9 + 6 * 120px / 9); animation-delay: calc(50ms * 7); }
                &:nth-child(8) { height: calc(120px / 9 + 7 * 120px / 9); width: calc(120px / 9 + 7 * 120px / 9); animation-delay: calc(50ms * 8); }
                &:nth-child(9) { height: calc(120px / 9 + 8 * 120px / 9); width: calc(120px / 9 + 8 * 120px / 9); animation-delay: calc(50ms * 9); }
            }
        }
    }
}

@keyframes fingerprint-spinner-animation {
    100% {
        transform: rotate(360deg);
    }
}
