@import '../../../index.scss';

.exchange_products {
    margin-bottom: 70px;

    input[type=text] {
        padding: 5px;
        border: 1px solid rgb(198, 194, 194);
        border-radius: 4px;
    }

    .card {
        .list-group-item {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: $FS-14;
            font-weight: $FW-400;
            label{width: 112px;}
            p {
                margin: 0;
                font-size: $FS-16;
                font-weight: $FW-500;
                width: calc(100% - 119px);
            }
        }
    }
 .pagination{
    background-color: $body-background !important;
 }
}

.exchange_modal {
    .modal-body{
        h3 {
            color: $primary-color;
        }
.border-top{
    border-top:1px solid rgb(226, 224, 224); 
}
        div>div {
            display: flex;
            align-items: center;
        }

        label {
            font-size: $FS-14;
            font-weight: $FW-600;
            width: 243px;
        }

        p {
            font-size: $FS-16;
            font-weight: $FW-400;
            line-height: 16px;
            text-align: left;
            display: inline-block;
            margin: 0;
            text-align: left;
            margin-right: auto;
            width: calc(100% - 55px);
        }
    }
}